"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const assignProveAbVariant = async (cookies, assignProveVariant, setCookie) => {
    if (!cookies.proveAb) {
        const SEVEN_DAYS_FROM_NOW = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
        try {
            const data = await assignProveVariant({
                testSlug: 'consumer_prove_no_store_test',
            }).unwrap();
            setCookie('proveAb', data, {
                expires: SEVEN_DAYS_FROM_NOW,
            });
            if (data.variant.name === 'treatment') {
                return 'treatment';
            }
            return 'control';
        }
        catch (error) {
            setCookie('proveAb', { variant: { name: 'control' } }, {
                expires: SEVEN_DAYS_FROM_NOW,
            });
            return 'control';
        }
    }
    else {
        return cookies.proveAb.variant.name;
    }
};
exports.default = assignProveAbVariant;
