"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const react_cookie_1 = require("react-cookie");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const apiActions = __importStar(require("api-actions"));
const config_1 = require("config");
const reducer_1 = require("../reducer");
function CreditDeclined({ requestPrequalification }) {
    const location = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [cookies] = (0, react_cookie_1.useCookies)(['proveAb']);
    const reApplyForPreQualification = () => {
        setLoading(true);
        const proveAbParticipantUuid = cookies.proveAb?.participantUuid;
        return requestPrequalification({
            ...location.state,
            ...(proveAbParticipantUuid
                ? { abParticipantUuid: proveAbParticipantUuid }
                : {}),
        }).then((prequalification) => {
            const { applicationStatus, lenderNextStepUrl } = prequalification;
            setLoading(false);
            if (applicationStatus === 'prequalified') {
                history.push({
                    pathname: config_1.WIZARD_URL,
                    state: {
                        step: 'PRE-QUALIFICATION',
                    },
                });
            }
            else if (applicationStatus === 'prequalified_account_exists') {
                history.push(config_1.PRE_QUALIFICATION_ACCOUNT_EXISTS_URL);
            }
            else if (applicationStatus === 'pending_approval') {
                // "An assumption is currently made that pending_approval is returned only for access loans. If additional product types associated with
                //  a pending_approval status will be supported, this code this have to be revisited to accommodate them."
                history.push({
                    pathname: config_1.ACCESS_LOAN_URL,
                    state: {
                        lenderNextStepUrl,
                        applicationStatus,
                    },
                });
            }
            else {
                history.push(config_1.PRE_QUALIFICATION_DECLINED_URL);
            }
        });
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "declined-container credit-declined", children: [(0, jsx_runtime_1.jsxs)("div", { className: "content", children: [(0, jsx_runtime_1.jsx)("h1", { className: "first-title", children: "Credit Application" }), (0, jsx_runtime_1.jsx)("p", { className: "first-subtitle", children: "We were unable to approve you for an Alphaeon Credit Card. You will receive an explanation letter in 7-10 business days." }), (0, jsx_runtime_1.jsx)("p", { children: "However, YOU MAY QUALIFY for an Alphaeon Credit Access Loan. Select Continue to find out if you are pre-approved with no impact to your credit score." })] }), (0, jsx_runtime_1.jsx)("div", { className: "check-qualification", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { className: "button-warn", disabled: loading, submitting: loading, onClick: () => reApplyForPreQualification(), children: "Continue" }) })] }));
}
function mapStateToProps(state) {
    return {
        personalInfo: reducer_1.selectors.personalInfo(state),
        practice: reducer_1.selectors.practice(state),
    };
}
const mapDispatchToProps = {
    requestPrequalification: apiActions.requestPrequalification,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(CreditDeclined);
