"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiMiddlewareRTK = exports.api = exports.middleware = exports.config = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const lodash_1 = require("lodash");
const lp_redux_api_1 = require("lp-redux-api");
const lp_requests_1 = require("lp-requests");
const config_1 = require("config");
const utils_1 = require("utils");
// Configure lp-redux-api middleware
// This function will be passed the request options before every request.
// You can use it to set additional options or override existing ones.
function before(options) {
    const hyperDxCookie = (0, utils_1.getCookie)('__rum_sid');
    const testAutomationCookie = (0, utils_1.getCookie)('TestAutomation');
    return {
        ...options,
        headers: {
            ...options.headers,
            'Hyperdx-Session-Info': hyperDxCookie,
            TestAutomation: testAutomationCookie,
        },
    };
}
function onSuccess(res) {
    return res;
}
function onFailure(httpError) {
    const clientErrors = [401, 403, 404];
    // An undefined httpError.status indicates a failure to send an API request, likely due
    // to the connection with the backend API not being available.
    const status = httpError?.status;
    if ((0, lodash_1.isNil)(status))
        return (0, utils_1.handleClientError)(config_1.ERROR_STATUS.API_DOWN);
    if (clientErrors.includes(status))
        return (0, utils_1.handleClientError)(status.toString());
    if (status === 503)
        return (0, utils_1.handleServerError)();
    return httpError;
}
// Global handler to trigger when API receives a response with a 401 code.
// This is invoked _in addition to_ the failureAction option.
function onUnauthorized() {
    return function ( /* dispatch */) {
        // dispatch(sessionsActions.logout())
        window.location.reload();
    };
}
exports.config = {
    root: process.env.REACT_APP_API_URL,
    before,
    onSuccess,
    onFailure,
    onUnauthorized,
    mode: 'cors',
};
exports.middleware = (0, lp_redux_api_1.middleware)(lp_requests_1.http, exports.config);
exports.api = (0, lp_requests_1.configureApi)(exports.config);
const apiMiddlewareRTK = () => (next) => (action) => {
    //run if RTK query API call
    if (!action.type.includes('merchantPortalApi') &&
        !action.type.includes('consumerPortalApi') &&
        !action.type.includes('internalPortalApi')) {
        return next(action);
    }
    if ((0, toolkit_1.isRejectedWithValue)(action)) {
        const clientErrors = [401, 403, 404];
        const status = action?.payload?.status;
        if ((0, lodash_1.isNil)(status))
            return (0, utils_1.handleClientError)(config_1.ERROR_STATUS.API_DOWN);
        if (clientErrors.includes(status))
            return (0, utils_1.handleClientError)(status.toString());
        if (status === 503)
            return (0, utils_1.handleServerError)();
    }
    return next(action);
};
exports.apiMiddlewareRTK = apiMiddlewareRTK;
