"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const components_1 = require("components");
const config_1 = require("config");
const merchant_portal_components_1 = require("merchant-portal-components");
const utils_1 = require("utils");
const forms_1 = require("../forms");
const showAdjustButton = (data) => {
    return (data.status === 'approved' ||
        (config_1.VOIDABLE_STATES.includes(data.status) &&
            (0, utils_1.mapTransactionTypeandInstrumentTypeToProductType)(data.transactionType, data.instrumentType) != config_1.PRODUCT_TYPE_MAP.installment));
};
function TransactionTable({ salesReports, allTransactions, displayedTransactions, formKey, initialValues, handlePageChange, handleFilters, }) {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(forms_1.TransactionFilterForm, { name: `transactionFilterForm-${formKey}`, onSubmit: handleFilters, initialValues: initialValues }), !salesReports ? ((0, jsx_runtime_1.jsx)(components_1.Spinner, {})) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: allTransactions.length > 0 ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(lp_components_1.SortableTable, { data: displayedTransactions, initialAscending: false, children: [(0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "name", label: "Customer Name", component: ({ value, }) => {
                                        return (0, jsx_runtime_1.jsx)("td", { children: (0, utils_1.getFullName)(value) });
                                    }, disabled: true }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "transactionType", label: "Product Type", valueGetter: ({ transactionType, instrumentType, }) => (0, utils_1.mapTransactionTypeandInstrumentTypeToProductType)(transactionType, instrumentType) }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "transactionType", label: "Trans Type", format: utils_1.formatUpperCase }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "status", label: "Trans Status", headerComponent: MinTableHeader170, format: utils_1.formatUpperCase }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "alphaeonAccountNumber", label: "Account / Loan Number", headerComponent: MinTableHeader140, disabled: true }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "effectiveAt", label: "Service / Trans Date", disabled: true, component: ServiceDateCell }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "amount", label: "Amount", disabled: true, format: (amount) => (0, utils_1.formatCurrency)(amount, 2) }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "action", label: "Action", component: TransactionActionCell, disabled: true })] }), (0, jsx_runtime_1.jsx)("table", { children: (0, jsx_runtime_1.jsx)("tfoot", { children: (0, jsx_runtime_1.jsxs)("tr", { children: [(0, jsx_runtime_1.jsx)("td", { colSpan: 2 }), (0, jsx_runtime_1.jsxs)("td", { className: "bold", children: ["TOTAL", ' ', allTransactions &&
                                                    (0, utils_1.formatCurrency)((0, utils_1.calcTransaction)(allTransactions), 2)] })] }) }) }), (0, jsx_runtime_1.jsx)("div", { className: "m-b-20", children: (0, jsx_runtime_1.jsx)(components_1.Pagination, { handlePageChange: handlePageChange, data: allTransactions }) })] })) : ((0, jsx_runtime_1.jsx)("div", { className: "empty-state-container", children: (0, jsx_runtime_1.jsx)("p", { children: "No Transactions" }) })) }))] }));
}
function mapStateToProps( /* state */) {
    return {};
}
const mapDispatchToProps = {};
const TransactionActionCell = ({ data, }) => {
    const isInstallment = data.transactionType === config_1.TRANSACTION_TYPE.LOAN;
    const buttonLink = isInstallment
        ? `${config_1.ACCOUNT_DETAIL_URL}${data.alphaeonAccountNumber}${config_1.LOAN_ADJUSTMENT_URL}`
        : `${config_1.VOID_SALE_URL}${data.alphaeonAccountNumber}`;
    return ((0, jsx_runtime_1.jsxs)("td", { className: "actions", children: [(0, jsx_runtime_1.jsx)(merchant_portal_components_1.AuthorizedLink, { to: `${config_1.ACCOUNT_DETAIL_URL}${data.alphaeonAccountNumber}`, requiredPermission: config_1.PERMISSION.ACCOUNT_LOOKUP, children: "VIEW" }), showAdjustButton(data) && ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.AuthorizedLink, { to: {
                    pathname: `${buttonLink}`,
                    state: {
                        alphaeonAccountNumber: data.alphaeonAccountNumber,
                        creditLimit: data.amount,
                        id: data.alphaeonAccountNumber,
                        name: data.name,
                        transaction: {
                            amount: data.amount,
                            planName: data.planName,
                            locationName: data.locationName,
                            transactionId: data.id,
                            createdAt: data.createdAt,
                            transactionType: data.transactionType,
                            status: data.status,
                            voidedAt: data.voidedAt,
                        },
                    },
                }, requiredPermission: config_1.PERMISSION.PROCESS_VOID, children: "ADJUST" }))] }));
};
const MinTableHeader170 = (data) => {
    return (0, jsx_runtime_1.jsx)("th", { className: "col-width-170px", children: data.column.label });
};
const MinTableHeader140 = (data) => {
    return (0, jsx_runtime_1.jsx)("th", { className: "col-width-140px", children: data.column.label });
};
const ServiceDateCell = ({ data: { transactionType, effectiveAt, installmentServiceDate }, }) => {
    if (transactionType === config_1.TRANSACTION_TYPE.LOAN) {
        return ((0, jsx_runtime_1.jsx)("td", { children: (0, utils_1.formatISODateStringAsUSMonthDayYear)(installmentServiceDate) }));
    }
    else {
        return (0, jsx_runtime_1.jsx)("td", { children: (0, utils_1.formatISODateStringAsUSMonthDayYear)(effectiveAt) });
    }
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(TransactionTable);
